@tailwind base;
@tailwind components;
@tailwind utilities;

@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Overpass:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: #fff;
}
header,
footer,
main,
nav,
section,
article,
figure,
figcaption {
  display: block;
}
html,
body,
header,
footer,
main,
nav,
section,
article,
figure,
figcaption,
div,
p,
ul,
ol,
dl,
li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
i {
  font-style: normal;
}

img {
  vertical-align: bottom;
  border: none;
}
video {
  vertical-align: bottom;
}

::selection {
  color: #fff;
  background: #000;
  opacity: 1;
}

html {
  background: #fff;
}
body {
  line-height: 1;
  color: #000;
  background: #fff;
  font-family: "Noto Sans JP", sans-serif;
}
a {
  color: #000;
  text-decoration: none;
}

#header .logo img:nth-child(2) {
  display: none;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body .hide-header#header {
  transform: translateY(-100%);
}

.button-small-container {
  color: #5932ff;
  width: 84px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins, "Noto Sans JP", sans-serif;
  border-radius: 20px;
  cursor: pointer;
}

.button-small-container.active {
  color: #fff;
  background: #5932ff;
}

.button-big-container {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 44px;
  padding-bottom: 2px;
  color: #fff;
  border: none;
  background: rgba(89, 50, 255, 1);
  border-radius: 30px;
  cursor: pointer;
  transition: all 300ms ease-out;
}

.button-big-container:hover {
  background: rgba(89, 50, 255, 0.7);
}

.button-big-outlined {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 310px;
  height: 44px;
  top: 0;
  font-size: 15px;
  font-family: Poppins, "Noto Sans JP", sans-serif;
  border: 2px solid #000;
  border-radius: 30px;
  cursor: pointer;
  transition: all 300ms ease-out;
}

.button-big-outlined.disabled {
  opacity: 0.2;
  pointer-events: none;
}

/* style for mobile */
@media screen and (max-width:1023px) {
  .button-small-container {
    width: 54px;
    height: 30px;
    font-size: 11px;
  }

  .button-big-container {
    height: 34px;
    font-size: 13px;
  }

  .button-big-outlined {
    width: 250px;
    height: 34px;
    font-size: 14px;
    border-radius: 20px;
  }
}

.oikos-compact table th, td {
  text-indent: 0;
  border: 2px solid #d6d8dc!important;
  padding: 13px;
}